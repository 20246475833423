$font-family-base: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
$base-font-size: 1rem;
$spacer: 0.25rem;

$gray-100: #eeeeee;
$gray-200: #cccccc;
$gray-300: #aaaaaa;
$gray-400: #888888;
$gray-500: #666666;
$gray-600: #444444;
$gray-700: #222222;

$parchment-100: #fbf6ed;
$parchment-200: #fff2dc;
$parchment-300: #fdebcd;
$parchment-400: #fce4bc;
$parchment-500: #f8ddae;
$parchment-600: #eece96;
$parchment-700: #dab87f;

$primary-100: #97c4d3;
$primary-200: #79abbb;
$primary-300: #5b8b9b;
$primary-400: #437080;
$primary-500: #2b4b56;
$primary-600: #1a3f4b;
$primary-700: #10313b;

$secondary-100: #97c4d3;
$secondary-200: #79abbb;
$secondary-300: #5b8b9b;
$secondary-400: #437080;
$secondary-500: #2e5968;
$secondary-600: #1a3f4b;
$secondary-700: #10313b;

$cha-400: #fdc50a;
$str-400: #e23011;
$int-400: #639bff;
$agi-400: #0fdd2e;

body {
  background-color: $parchment-200;
  color: $primary-700;
  margin: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $base-font-size;
  line-height: $base-font-size * 1.5;
}

p {
    margin-top: 0;
    font-size: $base-font-size;
}

a {
    color: $primary-700;
}

a:active {
    color: $primary-500;
}

a:visited {
    color: $primary-300;
}

ul {
    margin: 0 0 $spacer * 4 0;
}

// Utility classes

.pixelated {
    image-rendering: pixelated;
}

.transition-fix {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
}

.overflow-hidden { overflow: hidden !important; }
.overflow-x-auto { overflow-x: auto !important; overflow-y: hidden !important; }
.overflow-y-auto { overflow-y: auto !important; overflow-x: hidden !important; }
.overflow-scroll { overflow: scroll; }
.overflow-visible { overflow: visible !important; }

.shadow-1 { box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);}
.shadow-2 { box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.3);}

.vh-100 { height: 100vh !important; }
.vw-100 { width: 100vw !important; }

.w-game { min-width: 340px; max-width: 768px !important; margin: auto !important; }
.w-game-compact { min-width: 340px; max-width: 768px; margin: 0px; }
.w-100 { width: 100% !important; }
.w-50 { width: 50% !important; }
.w-container { width: 250px !important; }

.flex-wrap { flex-wrap: wrap; }

.pointer-events-none {
    pointer-events: none;
}

.user-select-none {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    touch-action: none;
}

.touch-action-none {
    touch-action: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.invisible {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.small {
    font-size: $base-font-size * 0.85;
}

.xsmall {
    font-size: $base-font-size * 0.70;
}

.conversation {
    font-size: $base-font-size * 1.25;
    line-height: $base-font-size * 1.75;

    p {
        margin-top: 0;
        font-size: $base-font-size * 1.25;
        margin-bottom: $spacer * 2;
    }
}

.narrative {
    font-size: $base-font-size * 2;
    line-height: $base-font-size * 2;

    p {
        margin-top: 0;
        font-size: $base-font-size * 2;
        margin-bottom: $spacer * 2;
    }
}

// Typography

.text-white {
    color: #ffffff !important;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: $spacer * 6;
    line-height: 1.2;
}

h1, .h1 { margin-bottom: $spacer * 6 !important; font-size: $base-font-size * 2.5 !important; }
h2, .h2 { margin-bottom: $spacer * 5 !important; font-size: $base-font-size * 2 !important; }
h3, .h3 { margin-bottom: $spacer * 4 !important; font-size: $base-font-size * 1.75 !important; }
h4, .h4 { margin-bottom: $spacer * 3 !important; font-size: $base-font-size * 1.5 !important; }
h5, .h5 { margin-bottom: $spacer * 2 !important; font-size: $base-font-size * 1.25 !important; }

.d1 { margin-bottom: $spacer * 6 !important; font-size: $base-font-size * 6 !important; }
.d2 { margin-bottom: $spacer * 5 !important; font-size: $base-font-size * 5 !important; }
.d3 { margin-bottom: $spacer * 4 !important; font-size: $base-font-size * 4 !important; }
.d4 { margin-bottom: $spacer * 3 !important; font-size: $base-font-size * 3.5 !important; }
.d5 { margin-bottom: $spacer * 2 !important; font-size: $base-font-size * 3 !important; }

// Gaps

.gap-1 > * { margin-right: $spacer !important; }
.gap-1 > *:last-child { margin-right: 0 !important; }
.gap-2 > * { margin-right: $spacer * 2 !important; }
.gap-2 > *:last-child { margin-right: 0 !important; }
.gap-3 > * { margin-right: $spacer * 4 !important; }
.gap-3 > *:last-child { margin-right: 0 !important; }
.gap-4 > * { margin-right: $spacer * 6 !important; }
.gap-4 > *:last-child { margin-right: 0 !important; }
.gap-5 > * { margin-right: $spacer * 12 !important; }
.gap-5 > *:last-child { margin-right: 0 !important; }

.vgap-1 > * { margin-bottom: $spacer !important; }
.vgap-1 > *:last-child { margin-bottom: 0 !important; }
.vgap-2 > * { margin-bottom: $spacer * 2 !important; }
.vgap-2 > *:last-child { margin-bottom: 0 !important; }
.vgap-3 > * { margin-bottom: $spacer * 4 !important; }
.vgap-3 > *:last-child { margin-bottom: 0 !important; }
.vgap-4 > * { margin-bottom: $spacer * 6 !important; }
.vgap-4 > *:last-child { margin-bottom: 0 !important; }
.vgap-5 > * { margin-bottom: $spacer * 12 !important; }
.vgap-5 > *:last-child { margin-bottom: 0 !important; }

.grid-gap-1 > * { margin-bottom: $spacer !important; }
.grid-gap-2 > * { margin-bottom: $spacer * 2 !important; }
.grid-gap-3 > * { margin-bottom: $spacer * 4 !important; }
.grid-gap-4 > * { margin-bottom: $spacer * 6 !important; }
.grid-gap-5 > * { margin-bottom: $spacer * 12 !important; }

// Display
.d-flex { display: flex !important; }
.d-none { display: none !important; }
.d-inline-block { display: inline-block !important; }
.d-inline { display: inline !important; }

// Flexbox
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.flex-column-reverse { flex-direction: column-reverse; }

// Float
.float-left { float: left; }
.float-right { float: right; }

.justify-content-start { justify-content: flex-start !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-stretch { justify-content: stretch !important; }

.align-items-start { align-items: flex-start !important; }
.align-items-between { align-items: space-between !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-center { align-items: center !important; }
.align-items-stretch { align-items: stretch !important; }

.flex-grow-1 { flex-grow: 1 !important; }
.flex-grow-0 { flex-grow: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }

.text-align-center { text-align: center !important; }


// Padding
.p-5 { padding: $spacer * 12 !important; }
.p-4 { padding: $spacer * 6 !important; }
.p-3 { padding: $spacer * 4 !important; }
.p-2 { padding: $spacer * 2 !important; }
.p-1 { padding: $spacer !important; }
.p-0 { padding: 0 !important; }

.pt-5 { padding-top: $spacer * 12 !important; }
.pt-4 { padding-top: $spacer * 6 !important; }
.pt-3 { padding-top: $spacer * 4 !important; }
.pt-2 { padding-top: $spacer * 2 !important; }
.pt-1 { padding-top: $spacer !important; }
.pt-0 { padding-top: 0 !important; }

.pb-5 { padding-bottom: $spacer * 12 !important; }
.pb-4 { padding-bottom: $spacer * 6 !important; }
.pb-3 { padding-bottom: $spacer * 4 !important; }
.pb-2 { padding-bottom: $spacer * 2 !important; }
.pb-1 { padding-bottom: $spacer !important; }
.pb-0 { padding-bottom: 0 !important; }

.pl-5 { padding-left: $spacer * 12 !important; }
.pl-4 { padding-left: $spacer * 6 !important; }
.pl-3 { padding-left: $spacer * 4 !important; }
.pl-2 { padding-left: $spacer * 2 !important; }
.pl-1 { padding-left: $spacer !important; }
.pl-0 { padding-left: 0 !important; }

.pr-5 { padding-right: $spacer * 12 !important; }
.pr-4 { padding-right: $spacer * 6 !important; }
.pr-3 { padding-right: $spacer * 4 !important; }
.pr-2 { padding-right: $spacer * 2 !important; }
.pr-1 { padding-right: $spacer !important; }
.pr-0 { padding-right: 0 !important; }

.px-5 { padding-right: $spacer * 12 !important; padding-left: $spacer * 12 !important; }
.px-4 { padding-right: $spacer * 6 !important; padding-left: $spacer * 6 !important; }
.px-3 { padding-right: $spacer * 4 !important; padding-left: $spacer * 4 !important; }
.px-2 { padding-right: $spacer * 2 !important; padding-left: $spacer * 2 !important; }
.px-1 { padding-right: $spacer !important; padding-left: $spacer !important; }
.px-0 { padding-right: 0 !important; padding-left: 0 !important; }

.py-5 { padding-top: $spacer * 12 !important; padding-bottom: $spacer * 12 !important; }
.py-4 { padding-top: $spacer * 6 !important; padding-bottom: $spacer * 6 !important; }
.py-3 { padding-top: $spacer * 4 !important; padding-bottom: $spacer * 4 !important; }
.py-2 { padding-top: $spacer * 2 !important; padding-bottom: $spacer * 2 !important; }
.py-1 { padding-top: $spacer !important; padding-bottom: $spacer !important; }
.py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }

// Margins
.m-5 { margin: $spacer * 12 !important; }
.m-4 { margin: $spacer * 6 !important; }
.m-3 { margin: $spacer * 4 !important; }
.m-2 { margin: $spacer * 2 !important; }
.m-1 { margin: $spacer !important; }
.m-0 { margin: 0 !important; }

.mt-5 { margin-top: $spacer * 12 !important; }
.mt-4 { margin-top: $spacer * 6 !important; }
.mt-3 { margin-top: $spacer * 4 !important; }
.mt-2 { margin-top: $spacer * 2 !important; }
.mt-1 { margin-top: $spacer !important; }
.mt-0 { margin-top: 0 !important; }

.mb-5 { margin-bottom: $spacer * 12 !important; }
.mb-4 { margin-bottom: $spacer * 6 !important; }
.mb-3 { margin-bottom: $spacer * 4 !important; }
.mb-2 { margin-bottom: $spacer * 2 !important; }
.mb-1 { margin-bottom: $spacer !important; }
.mb-0 { margin-bottom: 0 !important; }

.ml-5 { margin-left: $spacer * 12 !important; }
.ml-4 { margin-left: $spacer * 6 !important; }
.ml-3 { margin-left: $spacer * 4 !important; }
.ml-2 { margin-left: $spacer * 2 !important; }
.ml-1 { margin-left: $spacer !important; }
.ml-0 { margin-left: 0 !important; }

.mr-5 { margin-right: $spacer * 12 !important; }
.mr-4 { margin-right: $spacer * 6 !important; }
.mr-3 { margin-right: $spacer * 4 !important; }
.mr-2 { margin-right: $spacer * 2 !important; }
.mr-1 { margin-right: $spacer !important; }
.mr-0 { margin-right: 0 !important; }

.mx-5 { margin-right: $spacer * 12 !important; margin-left: $spacer * 12 !important; }
.mx-4 { margin-right: $spacer * 6 !important; margin-left: $spacer * 6 !important; }
.mx-3 { margin-right: $spacer * 4 !important; margin-left: $spacer * 4 !important; }
.mx-2 { margin-right: $spacer * 2 !important; margin-left: $spacer * 2 !important; }
.mx-1 { margin-right: $spacer !important; margin-left: $spacer !important; }
.mx-0 { margin-right: 0 !important; margin-left: 0 !important; }

.my-5 { margin-top: $spacer * 12 !important; margin-bottom: $spacer * 12 !important; }
.my-4 { margin-top: $spacer * 6 !important; margin-bottom: $spacer * 6 !important; }
.my-3 { margin-top: $spacer * 4 !important; margin-bottom: $spacer * 4 !important; }
.my-2 { margin-top: $spacer * 2 !important; margin-bottom: $spacer * 2 !important; }
.my-1 { margin-top: $spacer !important; margin-bottom: $spacer !important; }
.my-0 { margin-top: 0 !important; margin-bottom: 0 !important; }

// Position

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.layer-1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.layer-2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}

.layer-3 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
}

// Borders

$base-rounded-size : 0.25rem;
.rounded { border-radius: $base-rounded-size !important; }
.rounded-2 { border-radius: $base-rounded-size * 2 !important; }
.rounded-circle { border-radius: 50% !important; }
.unrounded { border-radius: 0 !important };

$base-border-color: $gray-600;
$dark-border-color: $gray-700;
.border { border: 2px solid $base-border-color !important; }
.border-primary { border-color: $primary-500 !important; }
.border-secondary { border-color: $secondary-500 !important; }
.border-parchment-100 { border-color: $parchment-100 !important; }
.border-parchment-300 { border-color: $parchment-300 !important; }
.border-parchment-500 { border-color: $parchment-500 !important; }
.border-parchment-700 { border-color: $parchment-700 !important; }
.border-left { border-left: 2px solid $base-border-color !important; }
.border-right { border-right: 2px solid $base-border-color !important; }
.border-top { border-top: 2px solid $base-border-color !important; }
.border-bottom { border-bottom: 2px solid $base-border-color !important; }
.border-dashed { border-style: dashed !important; }
.border-inset { box-shadow: inset 1px 1px 4px rgba(#000000, .25) !important }

.bg-parchment-100 { background-color: $parchment-100 !important; }
.bg-parchment-200 { background-color: $parchment-200 !important; }
.bg-parchment-300 { background-color: $parchment-300 !important; }
.bg-parchment-400 { background-color: $parchment-400 !important; }
.bg-parchment-500 { background-color: $parchment-500 !important; }
.bg-parchment-600 { background-color: $parchment-600 !important; }
.bg-parchment-700 { background-color: $parchment-700 !important; }

.bg-frosted-100 { background-color: rgba($parchment-100, 0.6) !important; backdrop-filter: blur(10px); }
.bg-frosted-200 { background-color: rgba($parchment-200, 0.6) !important; backdrop-filter: blur(10px); }
.bg-frosted-300 { background-color: rgba($parchment-300, 0.6) !important; backdrop-filter: blur(10px); }
.bg-frosted-400 { background-color: rgba($parchment-400, 0.6) !important; backdrop-filter: blur(10px); }
.bg-frosted-500 { background-color: rgba($parchment-500, 0.6) !important; backdrop-filter: blur(10px); }
.bg-frosted-600 { background-color: rgba($parchment-600, 0.6) !important; backdrop-filter: blur(10px); }
.bg-frosted-700 { background-color: rgba($parchment-700, 0.6) !important; backdrop-filter: blur(10px); }

.bg-primary { background-color: $primary-700 !important; }
.bg-white { background-color: #ffffff !important; }
.bg-black { background-color: #000000 !important; }
.bg-cha { background-color: $cha-400 !important; }
.bg-str { background-color: $str-400 !important; }
.bg-int { background-color: $int-400 !important; }
.bg-agi { background-color: $agi-400 !important; }

.bg-primary-frosted {
    background-color: rgba($primary-700, 0.7) !important;
    backdrop-filter: blur(10px);
}

.border-box {
    box-sizing: border-box;
}

// Buttons
.btn {
    background-color: $parchment-300;
    border-bottom: 2px solid $dark-border-color;
    border-left: 2px solid $base-border-color;
    border-right: 2px solid $dark-border-color;
    border-top: 2px solid $base-border-color;
    border-radius: $base-rounded-size * 2;
    box-shadow: inset 2px 2px 1px rgba($parchment-100, 1);
    color: $gray-700;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-base;
    font-size: $base-font-size;
    padding: $spacer * 2.25 $spacer * 3;
    text-align: center;
    text-decoration: none;
    transition: 0.25s;
    user-select: none;
    vertical-align: middle;
}

.btn.btn-dark {
    border: 1px solid $gray-700 !important;
    box-shadow: inset 0 -12px 8px rgba(#000000, .25);
}

.btn:hover, .btn-selected:hover {
    background-color: $parchment-500;
}

.btn:disabled {
    background-color: $gray-500;
}

.btn:active {
    background-color: $parchment-500;
}

.btn-selected {
    background-color: $parchment-600;
}

.btn-link {
    border: 0 !important;
    border-image-source: none !important;
    border-image-width: 0 !important;
    border-image-slice: inherit !important;
    border-image-repeat: inherit !important;
    padding: $spacer $spacer !important;
    background-color: transparent;
    box-shadow: none;
}

// Shakin'
@keyframes shakey {
	0% { transform: translate(2px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(0px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(2px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(2px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake {
	animation-name: shakey;
	animation-duration: 0.1s;
	transform-origin: 50% 50%;
	animation-iteration-count: 1;
	animation-timing-function: linear;
}

.fade-in {
    animation: fadeIn linear 1s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    1000% { opacity: 1; }
}
